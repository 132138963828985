import {
  LOGIN_JWT_SUCCESS,
  TOGGLE_JWT_MODAL,
  LOGIN_JWT_FAIL,
  START_JWT_LOADING,
  GET_JWT_PROFILE,
  GET_JWT_PROFILE_FAIL,
  TOGGLE_USER_MODAL,
  LOGOUT_JWT
} from "../type";
import * as services from "../../services/authJWT.services";
import { serverResponse } from "../../util/fakeServer";

export const loginJWT = (data) => (dispatch) => {
  dispatch({
    type: START_JWT_LOADING
  });
  const apiData = {
    ...data,
    signboard_id: serverResponse.config.signboard_id
  };
  services
    .login(apiData)
    .then((res) => {
      if (res) {
        const {
          data: { status, data: payload }
        } = res;

        if (status === 200) {
          return dispatch({
            type: LOGIN_JWT_SUCCESS,
            payload
          });
        }

        dispatch({
          type: LOGIN_JWT_FAIL,
          payload
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_JWT_FAIL,
        payload: err
      });
    });
};

export const getProfileJWT = () => (dispatch) => {
  services
    .getProfile()
    .then((res) => {
      if (res) {
        const {
          data: { status, data: payload }
        } = res;
        if (status === 200) {
          dispatch({
            type: GET_JWT_PROFILE,
            payload
          });
        } else {
          dispatch({
            type: GET_JWT_PROFILE_FAIL
          });
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_JWT_FAIL,
        payload: err
      });
    });
};

export const logoutJWT = () => (dispatch) => {
  services
    .logout()
    .then((data) => {
      dispatch({
        type: LOGOUT_JWT
      });
    })
    .catch((err) => {
      dispatch({
        type: LOGOUT_JWT
      });
    });
};

export const toggleJWTModal = () => (dispatch) => {
  dispatch({
    type: TOGGLE_JWT_MODAL
  });
};

export const toggleUserModal = () => (dispatch) => {
  dispatch({
    type: TOGGLE_USER_MODAL
  });
};
