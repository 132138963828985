import React from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

import { connect } from "react-redux";
import { logoutJWT, toggleUserModal } from "../../redux/authJWT/action";

const UserInfoModal = (props) => {
  const handleOnPress = () => {
    const confirmLogout = window.confirm("Vuoi effettuare il logout?");
    if (confirmLogout) {
      props.logoutJWT();
    }
  };

  const {
    authJWT: {
      user: { name, surname, email }
    }
  } = props;
  return (
    <Modal
      toggle={() => {
        props.toggleUserModal();
      }}
      onClosed={() => {
        // this.props.isModalOpen(false);
      }}
      centered
      isOpen={props.authJWT.modalUserOpen}
    >
      <ModalHeader>Benvenuto {name}</ModalHeader>
      <ModalBody>
        <div>
          <p>
            Email:
            <strong> {email}</strong>
          </p>
          <p>
            Nome:
            <strong> {name}</strong>
          </p>
          <p>
            Cognome:
            <strong> {surname}</strong>
          </p>
        </div>
        <div className="d-flex justify-content-end">
          <Button onClick={handleOnPress} className="mt-3 text-rigth">
            Logout
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    authJWT: state.authJWT
  };
};

export default connect(mapStateToProps, {
  logoutJWT,
  toggleUserModal
})(UserInfoModal);
