import React from "react";
import { Modal } from "reactstrap";
import { connect } from "react-redux";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { closeInteractivityModal } from "../../redux/modal/action";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage, sendAnalyticsEvent } from "../../helper/utility";
import Button from "reactstrap/lib/Button";
import ModalBody from "reactstrap/lib/ModalBody";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { isIOS } from "react-device-detect";
import Facebook from "../../assets/images/icons/facebook.svg";
import Whatsapp from "../../assets/images/icons/whatsapp.svg";
import ContentModal from "./modalbody/ContentModal";
import Marker from "../leaflet/markers/Marker";
import { serverResponse } from "../../util/fakeServer";
import { gaEventLabel } from "../../settings/gaConfig";
import ManagerFactory from "../../managers/ManagerFactory";
import LoaderModal from "../common/LoaderModal";

const ModalComponent = (props) => {
  const otherPropsModal = props.modalRedux.otherProps;
  console.log("THIS IS otherPropsMODDA", otherPropsModal);
  const fakeServerConfig = props.fakeServer.config;
  const productUrl = serverResponse.config.shareFlyerURL;
  const manager = ManagerFactory.getManager();

  const closeBtn = (
    <Button
      color="secondary"
      onClick={() => {
        if (props.zoom !== undefined) {
          props.zoom.reset();
        }
        props.closeInteractivityModal();
      }}
    >
      <i className="fas fa-arrow-left"></i>
      <strong> {language[defaultLanguage].indietro}</strong>
    </Button>
  );

  const shareOnFacebook = () => {
    sendAnalyticsEvent(
      gaEventLabel.share_product_facebook,
      String(otherPropsModal.info.item_id)
    );

    let fb_share_url = "https://www.facebook.com/share.php?u=";
    let page_url =
      productUrl + "/share/product.php?c=" + otherPropsModal.info.item_id;
    window.open(
      fb_share_url + encodeURIComponent(page_url),
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  const shareOnWhatsapp = () => {
    sendAnalyticsEvent(
      gaEventLabel.share_product_whatsapp,
      String(otherPropsModal.info.item_id)
    );

    let page_url =
      productUrl + "/share/product.php?c=" + otherPropsModal.info.item_id;
    if (window.ReactNativeWebView !== undefined && isIOS) {
      let link = encodeURIComponent(page_url);
      let title = serverResponse.config.shareProductSentence;
      var str = title + " " + link;
      var obj = { action: "whatsapp", value: str };
      window.ReactNativeWebView.postMessage(JSON.stringify(obj));
    } else {
      window.open(
        "http://api.whatsapp.com/send?text=" +
          serverResponse.config.shareProductSentence +
          " " +
          encodeURIComponent(page_url),
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
    }
  };

  // const link = otherPropsModal.info.markers.filter(
  //   (el) => el.type === "world"
  // )[0]
  //   ? otherPropsModal.info.markers.filter((el) => el.type === "world")[0].data
  //   : null;

  // const markers =

  const title = props.modalRedux.otherProps.markerInfo
    ? props.modalRedux.otherProps.markerInfo.title
    : "Dettaglio";
  const title_type = {
    play: "Video",
    // eslint-disable-next-line no-useless-computed-key
    ["hat-chef"]: "Ricetta",
    carousel: "Carosello",
    specs: "Scheda tecnica",
    info: "Curiosità",
    plus: "Dettaglio"
  };

  // const activedInteractivities = otherPropsModal.info.markers.map(
  //   (el) => el.type
  // );

  return (
    <Modal
      // style={{ height: isMobile ? "95vh" : "inherit" }}
      centered
      isOpen={props.modalRedux.isModalOpen}
      toggle={() => {
        props.closeInteractivityModal();
      }}
      style={{ maxHeight: "65vh" }}
      size="xl"
      onOpened={() => {
        // props.isModalOpen(true);
        if (otherPropsModal.disableSwipe !== undefined)
          otherPropsModal.disableSwipe();
      }}
      onClosed={() => {
        // this.props.isModalOpen(false);
        if (otherPropsModal.enableSwipe !== undefined)
          otherPropsModal.enableSwipe();
      }}
    >
      <ModalHeader
        toggle={() => props.closeInteractivityModal()}
        close={closeBtn}
      >
        {/* {language[defaultLanguage].descrizione} */}
        <span
          style={{
            textTransform: "capitalize",
            fontWeight: "600",
            fontSize: "1.4rem"
          }}
        >
          {title
            ? title
            : title_type[props.modalRedux.otherProps.markerInfo.type] ||
              "Interattività"}
        </span>
      </ModalHeader>
      <ModalBody>
        <Row style={{ marginBottom: "16px" }}>
          {props.modalRedux.loading ? (
            <LoaderModal width="50vw" height="50vh" />
          ) : (
            <Col style={{ display: "flex", height: "30px" }}>
              <div className="markers-container" style={{ width: "100%" }}>
                <div className="vertical" style={{ display: "flex" }}>
                  {otherPropsModal.info.markers
                    .filter(
                      (el) =>
                        el.type !== otherPropsModal.type &&
                        el.type !== manager.filterMarkers()
                    )
                    .map((element, index) => {
                      return (
                        <Marker
                          zoom={otherPropsModal.zoom}
                          disableSwipe={otherPropsModal.disableSwipe}
                          enableSwipe={otherPropsModal.enableSwipe}
                          key={index}
                          info={otherPropsModal.info}
                          markerInfo={element}
                          leafletSwipe={otherPropsModal.leafletSwipe}
                          navMarkers={otherPropsModal.navMarkers}
                          setSwipe={otherPropsModal.setSwipe}
                          isSwipeDisabed={otherPropsModal.isSwipeDisabed}
                          type={element.type}
                        />
                      );
                    })}
                </div>
                {!fakeServerConfig.hide_share_product && (
                  <img
                    src={Facebook}
                    className="share-icon"
                    style={{ marginLeft: "auto" }}
                    alt="facebook"
                    onClick={() => shareOnFacebook()}
                  />
                )}
                {!fakeServerConfig.hide_share_product && (
                  <img
                    src={Whatsapp}
                    className="share-icon"
                    alt="whatsapp"
                    onClick={() => shareOnWhatsapp()}
                  />
                )}
              </div>
            </Col>
          )}
        </Row>
        {/* CONTENUTO DINAMICO MODALE */}
        {!props.modalRedux.loading && (
          <div
            className={`row ${manager.getRowClass(
              props.modalRedux.otherProps.type
            )}`}
            style={{
              justifyContent: "center",
              maxHeight: "60vh",
              overflowY: "overlay"
            }}
          >
            <ContentModal {...props.modalRedux.otherProps} />

            {/* {link && manager.getStoreButton(link)} */}
          </div>
        )}
      </ModalBody>
      {/* <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            if (props.zoom !== undefined) {
              otherPropsModal.zoom.reset();
            }
            props.closeInteractivityModal();
            // this.setModal(true);
          }}
        >
          {language[defaultLanguage].chiudi}
        </Button>
      </ModalFooter> */}

      {/* <ModalFooter
        style={{
          justifyContent: "space-between",
          flexWrap: "nowrap",
          padding: 0,
          backgroundColor: "rgb(248,249,250)",
          border: 0,
          height: "40px"
        }}
      >
        {Object.keys(title_type).map((el, index) => {
          return (
            <button
              key={`${el}_${index}`}
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                borderTop:
                  otherPropsModal.type === el
                    ? `3px solid ${serverResponse.config.secondary_color}`
                    : "",
                textAlign: "center",
                color:
                  otherPropsModal.type === el ||
                  activedInteractivities.includes(el)
                    ? `black`
                    : "lightgray",
                fontWeight: otherPropsModal.type === el ? "bold" : ""
              }}
              className={`w-100 h-100 m-0 btn `}
            >
              <p>{title_type[el]} </p>
              <img
                alt="icon"
                src={iconsObj[el].icon}
                className={`${
                  otherPropsModal.type === el ? "vertical" : ""
                } img-fluid`}
                style={{ width: "25px", marginLeft: 10 }}
              />
            </button>
          );
        })}
      </ModalFooter> */}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal,
    fakeServer: state.fakeServer
  };
};

export default connect(mapStateToProps, {
  closeInteractivityModal
})(ModalComponent);
