import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  InputGroup,
  InputGroupAddon,
  Button
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen, faUser } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { loginJWT, toggleJWTModal } from "../../redux/authJWT/action";
import Loader from "../common/Loader";

const widthIcon = 38;

const LoginModal = (props) => {
  const [form, setForm] = useState({
    email: "",
    password: ""
  });

  const [showPassword, setShowPassword] = useState(false);

  const onChange = ({ name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const handleOnPress = () => {
    setForm({
      email: "",
      password: ""
    });
    props.loginJWT(form);
  };

  return (
    <Modal
      toggle={() => {
        props.toggleJWTModal();
      }}
      onClosed={() => {
        // this.props.isModalOpen(false);
        setForm({
          email: "",
          password: ""
        });
      }}
      centered
      isOpen={props.authJWT.modalJwtOpen}
    >
      <ModalHeader>Login</ModalHeader>
      <ModalBody>
        {props.authJWT.loading ? (
          <Loader
            containerWidth="20vw"
            containerHeight="20vh"
            background={"transparent"}
          />
        ) : (
          <>
            <InputGroup>
              <InputGroupAddon addonType="append">
                <button
                  className="input-group-text"
                  style={{ height: `${widthIcon}px`, width: `${widthIcon}px` }}
                >
                  <FontAwesomeIcon icon={faUser} />
                </button>
              </InputGroupAddon>

              <Input
                type="email"
                name="search"
                id="email_login"
                placeholder={"Email"}
                onChange={(e) => {
                  onChange({ name: "email", value: e.target.value });
                }}
                value={form.username}
              />
            </InputGroup>
            <InputGroup className="mt-3">
              <InputGroupAddon
                onClick={() => {
                  setShowPassword((el) => !el);
                }}
                addonType="append"
              >
                <button
                  className="input-group-text"
                  style={{ height: `${widthIcon}px`, width: `${widthIcon}px` }}
                >
                  <FontAwesomeIcon icon={!showPassword ? faLock : faLockOpen} />
                </button>
              </InputGroupAddon>

              <Input
                type={`${!showPassword ? "password" : "text"}`}
                name="search"
                id="password_login"
                placeholder={"Password"}
                onChange={(e) => {
                  onChange({ name: "password", value: e.target.value });
                }}
                value={form.password}
              />
            </InputGroup>
            {!!props.authJWT.error && (
              <div style={{ color: "red" }}>
                <p>{props.authJWT.error}</p>
              </div>
            )}

            <div className="d-flex justify-content-end">
              <Button onClick={handleOnPress} className="mt-3 text-rigth">
                Login
              </Button>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    authJWT: state.authJWT
  };
};

export default connect(mapStateToProps, {
  loginJWT,
  toggleJWTModal
})(LoginModal);
