import axios from "axios";
import { serverResponse } from "../util/fakeServer";

// console.log("THIS IS COOKIE", cookie);
// axios.defaults.headers.common["Cookie"] = "session_id" + cookie;

const url = "https://viewmanager.interattivo.net/api/user/";
// const url = "http://192.168.1.47/viewmanager/api/user/";

const API = axios.create({
  withCredentials: true,
  baseURL: url,
  validateStatus: function(status) {
    return status < 300;
  }
});

// API.interceptors.request.use(
//   (req) => {
//     req.headers.clientId = `${serverResponse.config.client_id}`;
//     req.headers.signboardId = `${serverResponse.config.signboard_id}`;
//     return req;
//   },
//   (err) => Promise.reject(err)
// );

export const getCookie = (data) => API.get("get-cookie");
export const setCookie = (data) => API.get("set-cookie");

export const login = (data) => API.post("login", data);
export const getProfile = () =>
  API.post("init", {
    signboard_id: serverResponse.config.signboard_id,
    only_registered: false
  });

export const logout = () => API.post("logout");
