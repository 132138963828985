import { serverResponse } from "../../util/fakeServer";
import {
  OPEN_INTERACTIVITY_MODAL,
  CLOSE_INTERACTIVITY_MODAL,
  OPEN_RELATED,
  SET_ERROR_MODAL,
  OPEN_INTERACTIVITY_MODAL_STATIC,
  START_LOADING_MODAL,
  END_LOADING_MODAL
} from "../type";
import * as services from "../../services/product.services";

export const openInteractivityModal = (obj, id_code) => async (dispatch) => {
  const isDynamic = serverResponse.config.dynamic_modal;
  dispatch({
    type: START_LOADING_MODAL
  });
  if (isDynamic && obj.type === "plus") {
    try {
      const {
        data: { data }
      } = await services.getProduct(id_code);

      dispatch({
        type: OPEN_INTERACTIVITY_MODAL,
        payload: { info: data[0], otherProps: obj }
      });
      dispatch({
        type: END_LOADING_MODAL
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR_MODAL,
        payload: error
      });
      dispatch({
        type: END_LOADING_MODAL
      });
    }
  } else {
    dispatch({
      type: OPEN_INTERACTIVITY_MODAL_STATIC,
      payload: { info: obj.info, otherProps: obj }
    });

    /* DA RIMUOVERE*/ dispatch({
      type: OPEN_INTERACTIVITY_MODAL,
      payload: { info: obj.info, otherProps: obj }
    });
  }
};

export const openRelated = (content) => (dispatch) => {
  dispatch({
    type: OPEN_RELATED,
    payload: content
  });
};

export const closeInteractivityModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_INTERACTIVITY_MODAL
  });
};
