import axios from "axios";
import { serverResponse } from "../util/fakeServer";

const url = "http://192.168.1.47/viewmanager/api/products/";

const API = axios.create({
  baseURL: url,
  validateStatus: function(status) {
    return status < 300;
  }
});

API.interceptors.request.use(
  (req) => {
    req.headers.clientId = `${serverResponse.config.client_id}`;
    req.headers.signboardId = `${serverResponse.config.signboard_id}`;
    return req;
  },
  (err) => Promise.reject(err)
);

export const getPrices = (arr, id_listino) =>
  API.post("get-prices", { skuls: arr, id_listino: id_listino });

export const getProduct = (data) =>
  API.post(`get-product`, {
    skul: data
  });

export const checkCart = (data) => API.post("check-cart", data);
