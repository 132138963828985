import React from "react";

const Price = ({
  strike_price,
  price,
  offer_price,
  discount_rate,
  price_label,
  no_price,
  fontSize = "1.8rem",
  styleContainer = {},
  styleParagraph = "unset",
  customClass = true,
  colCustom = "col-12"
}) => {
  const formattedPrice = (el) =>
    `€ ${parseFloat(el)
      .toFixed(2)
      .toString()
      .replace(",", ".")}`;

  console.log("this is price ===> ", price);

  const classes = "d-flex flex-column custom_pt-3";

  return (
    <>
      {/* {strike_price && parseFloat(strike_price) !== parseFloat(price) && (
        <div className="col-12">
          <h4 className="barrato">&euro; {strike_price}</h4>
        </div>
      )} */}
      {offer_price && !no_price && (
        <div className={`${colCustom} product-price`} style={styleContainer}>
          <p
            className={`${customClass ? classes : ""}`}
            style={{ fontWeight: "bold", paddingRight: styleParagraph }}
          >
            <sup
              className="barrato"
              style={{ color: "grey", fontSize: "12px" }}
            >
              {formattedPrice(price)}
            </sup>{" "}
            {discount_rate !== undefined &&
              parseFloat(discount_rate) !== 0 &&
              discount_rate !== null && (
                <span className="discount-rate">{discount_rate}%</span>
              )}
            <span style={{ fontSize: fontSize }}>
              {offer_price ? formattedPrice(offer_price) : price_label}
            </span>
          </p>
        </div>
      )}
      {!no_price && !offer_price && (
        <div className={`${colCustom} product-price`} style={styleContainer}>
          <p style={{ fontWeight: "bold", paddingRight: styleParagraph }}>
            <span style={{ fontSize: fontSize }}>
              {offer_price ? formattedPrice(offer_price) : price_label}
            </span>
            {discount_rate !== undefined &&
              parseFloat(discount_rate) !== 0 &&
              discount_rate !== null && (
                <span className="discount-rate">{discount_rate}%</span>
              )}
          </p>
        </div>
      )}
    </>
  );
};

export default Price;
