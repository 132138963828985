import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faPlus,
  // faMinus,
  faMinusCircle,
  faPlusCircle
} from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/grocery-product.css";
import { CustomInput, Tooltip } from "reactstrap";

import { connect } from "react-redux";
import actions from "../../redux/groceryList/action";
import { serverResponse } from "../../util/fakeServer";
import RemoveProd from "../../assets/images/icons/remove_prod.svg";
import CartRemove from "../../assets/images/icons/Cart_remove.svg";

import { strings } from "../../util/data/strings";
import { isMobile } from "react-device-detect";

const img_custom = require("assets/images/prodotto.png");

class GroceryProduct extends Component {
  state = {
    deleteTooltipOpen: false
  };

  incrementQty = () => {
    let qty =
      this.props.quantity +
      (this.props.quantity_step ? this.props.quantity_step : 1);
    this.props.changeQuantity({
      quantity: qty,
      item: this.props
    });
  };

  decrementQty = () => {
    let qty = this.props.quantity;
    if (this.props.quantity_step) {
      if (qty > this.props.quantity_step) {
        qty -= this.props.quantity_step;
      }
    } else if (qty > 1) {
      qty--;
    }

    this.props.changeQuantity({
      quantity: qty,
      item: this.props
    });
  };

  checkProduct = () => {
    this.props.checkProduct({
      item: this.props
    });
  };

  deleteProduct = () => {
    this.props.deleteProduct({
      item: this.props
    });
    if (document.getElementById("selected-" + this.props.id) != null)
      document.getElementById("selected-" + this.props.id).style["display"] =
        "none";
  };

  toggleDeleteTooltip = () => {
    this.setState({ deleteTooltipOpen: !this.state.deleteTooltipOpen });
  };

  render() {
    const {
      title,
      price,
      variety,
      quantity,
      grocery_label,
      done,
      images,
      id,
      no_price
    } = this.props;

    const varieryLabel = variety ? (
      <p
        style={{
          maxWidth: "100px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap"
        }}
      >
        <br />
        {variety}
      </p>
    ) : null;

    const iconRemoveProduct = serverResponse.config.send_grocery_list_to_market
      ? CartRemove
      : RemoveProd;

    return (
      <ul
        className="list-inline pa-10 ma-0 ul-bottom-border flex ul-grocery"
        style={{
          alignItems: "center",
          width: isMobile ? "100vw" : "unset",
          overflowX: "hidden"
        }}
      >
        {false && (
          <li className="notification-popover-profile">
            <CustomInput
              type="checkbox"
              id={"checkbox-" + this.props.id}
              onChange={() => this.checkProduct()}
              checked={done}
            />
          </li>
        )}
        <li
          className="list-inline-item pa-5 mobile-grocery-product-container"
          style={{ alignItems: "center" }}
        >
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = img_custom;
            }}
            src={
              images &&
              images !== undefined &&
              images !== null &&
              images.length > 0
                ? process.env.PUBLIC_URL + images[0].image_file
                : serverResponse.config.clientIcon
            }
            alt="img"
            style={{ width: "15%", height: "15%", marginRight: "5px" }}
          />
          <h6 style={{ fontSize: "0.8rem" }} className="reduceMobile">
            {title} {varieryLabel} <br />
            {!no_price && `${price} €`}
          </h6>
          <div className="grocery-product-quantity">
            <FontAwesomeIcon
              icon={faMinusCircle}
              className="change-product-quantity-icon"
              style={{ marginRight: "10px" }}
              onClick={() => this.decrementQty()}
            />
            <h6>{quantity}</h6>
            <h6 style={{ marginLeft: "5px" }}>
              {grocery_label ? grocery_label : "pz."}
            </h6>
            <FontAwesomeIcon
              icon={faPlusCircle}
              className="change-product-quantity-icon"
              style={{ marginLeft: "10px" }}
              onClick={() => this.incrementQty()}
            />

            {/*<FontAwesomeIcon icon={faTrashAlt} style={{ color: "#ff0000", marginLeft: "20px" }} onClick={() => this.deleteProduct()} />*/}
            <img
              id={"btnDelete_" + id}
              src={iconRemoveProduct}
              style={{
                color: "#00ff00",
                marginLeft: "20px",
                width: "30px",
                cursor: "pointer"
              }}
              onClick={() => this.deleteProduct()}
              alt="img"
            />
            <Tooltip
              placement="right"
              isOpen={this.state.deleteTooltipOpen}
              target={"btnDelete_" + id}
              toggle={this.toggleDeleteTooltip}
            >
              {strings.deleteBtnTooltip}
            </Tooltip>
          </div>
        </li>
      </ul>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeQuantity: (newQuantity) => {
      dispatch(actions.changeQuantity(newQuantity));
    },
    checkProduct: (check) => {
      dispatch(actions.checkProduct(check));
    },
    deleteProduct: (deleted) => {
      dispatch(actions.deleteProduct(deleted));
    }
  };
}

export default connect(null, mapDispatchToProps)(GroceryProduct);
