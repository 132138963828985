export const styles = {
  container: {
    position: "absolute",
    // background: "white",
    bottom: 0,
    right: 0,
    width: `100%`,
    // width: `50%`,
    // fontSize: "10px",
    // fontWeight: "bold",
    textAlign: "right",
    paddingRight: "3px",
    // height: "25%",
    // border: "1px solid black"
    whiteSpace: "nowrap"
  }
};
